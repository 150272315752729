import React from "react";
import logo from "./lng.png";
import "./App.css";

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="logo" alt="Late Night Griller" />
        <h1>Late Night Griller</h1>
        <div id="Spotify-Player">

        <iframe title="Embedded Spotify Player for Late Night Griller" src="https://open.spotify.com/embed/artist/5nvsrqx2K3P8RlopEMagYF?utm_source=generator&theme=0" width="100%" height="152" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" frameBorder="0"></iframe>

        </div>
      </header>
    </div>
  );
};

export default App;
